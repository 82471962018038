import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timePrettify',
  standalone: true,
})
export class TimePrettifyPipe implements PipeTransform {

  currentTime = Date.now();
  transform(createdAt: number): string {
    let diffTime = this.currentTime - createdAt;
    let seconds = Math.floor(diffTime/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    if(days > 0) {
      if(days === 1) return `${days} day ago`
      else return `${days} days ago`
    }
    else if(hours > 0) {
      if(hours === 1) return `${hours} hour ago`
      else return `${hours} hours ago`
    }
    else if(minutes > 0) {
      if(minutes === 1) return `${minutes} minute ago`
      else return `${minutes} minutes ago`
    }
    else {
      if(seconds === 1) return `${seconds} second ago`
      if(seconds < 1) return `0 seconds ago`
      else return `${seconds} seconds ago`
    }
  }

}
