import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UsersService} from "../../../../_services/users.service";
import {GlobalStore} from "../../../../global.store";
import {take} from "rxjs";

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: []
})
export class RedirectComponent {
  constructor(readonly globalStore: GlobalStore,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UsersService) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => {
      switch (this.route.snapshot.data['type']) {
        case 'notificationSettings': {
          if (user.company) this.router.navigateByUrl(`/company/${user.company.id}/profile?notificationSettings`)
          else this.router.navigateByUrl(`/profile?notificationSettings`)
          return;
        }
        case 'not-found': {
          router.navigateByUrl(this.userService.getUserNotFoundUrl(user));
          return;
        }
      }
    })
  }
}
