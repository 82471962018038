import {Component} from '@angular/core';
import {NotificationService} from "../../../../_services/notification.service";
import {waitForElm} from "../../../../_util/utils";
import { SpriteVersionDirective } from '../../../../_directives/spriteVersionDirective';
import { NgClass, NgForOf } from '@angular/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [
    SpriteVersionDirective,
    NgClass, NgForOf
  ]
})
export class NotificationComponent {
  list: any[] = [];

  constructor(private notifService: NotificationService) {
    this.notifService.showNotification.subscribe((config) => {
      config.id = Math.floor(Math.random() * 100);
      this.list.push(config);

      waitForElm(`#notification-${config.id}`).then((el) => {
        this.toggleShow(el as HTMLElement, 100);
        this.toggleShow(el as HTMLElement, 2100);
        this.removeNotification(el as HTMLElement, config, 2350);
      })
    })
  }

  toggleShow(el: HTMLElement, delayMs: number) {
    setTimeout(() => {
      if (el) el.toggleAttribute('show')
    }, delayMs);
  }

  removeNotification(el: HTMLElement, config: any, delayMs: number) {
    setTimeout(() => {
      if (el) el.remove();
      this.list.splice(this.list.indexOf(config), 1);
    }, delayMs);
  }
}
