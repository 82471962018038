 import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/auth/login/login.component';
import {ForgotPasswordComponent} from './components/auth/forgot-password/forgot-password.component';
import {NgOptimizedImage} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CreateNewPasswordComponent} from './components/auth/create-new-password/create-new-password.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ErrorInterceptor} from "./_interceptors/error.interceptor";
import {FillAdditionalInfoComponent} from './components/auth/fill-additional-info/fill-additional-info.component';
import {NgxMaskDirective, provideEnvironmentNgxMask} from "ngx-mask";
import {AdminModule} from "./components/admin/admin.module";
import {HeadersInterceptor} from "./_interceptors/headers.interceptor";
 import { SpriteVersionDirective } from './_directives/spriteVersionDirective';
 import { LoaderDirective } from './_directives/loader.directive';
 import { SubHeaderModule } from './components/admin/components/sub-header/sub-header.module';
 import { NotificationComponent } from './components/admin/components/notification/notification.component';
 import {
   CountriesCodeSelectComponent
 } from './components/admin/components/contries-code-select/countries-code-select.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    CreateNewPasswordComponent,
    FillAdditionalInfoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskDirective,
    // AdminModule,
    SpriteVersionDirective,
    LoaderDirective,
    SubHeaderModule,
    NotificationComponent,
    CountriesCodeSelectComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    provideEnvironmentNgxMask()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
