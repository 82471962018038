import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubHeaderComponent } from './sub-header.component';
import { SimpleSelectorComponent } from './simple-selector/simple-selector.component';
import {
  EmailNotificationSettingsComponent
} from './email-notification-settings/email-notification-settings.component';
import { NgToggleModule } from 'ng-toggle-button';
import { SpriteVersionDirective } from '../../../../_directives/spriteVersionDirective';
import { LoaderDirective } from '../../../../_directives/loader.directive';
import { OutsideClickDirective } from '../../../../_directives/outside-click.directive';
import { RouterModule } from '@angular/router';
import { TimePrettifyPipe } from '../../../../_pipes/timePrettify.pipe';
import {VisibilityObserverDirective} from "../../../../_directives/visibilityObserver.directive";

const COMPONENTS = [
  SubHeaderComponent,
  SimpleSelectorComponent,
  EmailNotificationSettingsComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, NgToggleModule, SpriteVersionDirective, LoaderDirective, OutsideClickDirective, RouterModule, TimePrettifyPipe, VisibilityObserverDirective],
  exports: [...COMPONENTS],

})
export class SubHeaderModule { }
