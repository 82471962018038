import {AfterViewInit, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import countriesListJson from '../../../../../assets/data/countries_codes_with_flags.json'
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Country} from "../../../../_models/company";
import {User} from "../../../../_models/user";
import {take} from "rxjs";
import {GlobalStore} from "../../../../global.store";
import { OutsideClickDirective } from '../../../../_directives/outside-click.directive';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-countries-code-select',
  templateUrl: './countries-code-select.component.html',
  styleUrls: ['./countries-code-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountriesCodeSelectComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    OutsideClickDirective, NgForOf,
  ]
})
export class CountriesCodeSelectComponent implements ControlValueAccessor {
  @Input() isError: boolean = false;
  @Output() change = new EventEmitter<any>();

  countriesList: Country[] = [...countriesListJson];
  selectedCountry: Country;

  onChange: any = () => {};
  onTouch: any = () => {
  }

  constructor(private globalStore: GlobalStore) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  findCurrentCountry(code: string, isDialCode: boolean) {
    if (isDialCode) return this.countriesList.find(country => country.dial_code === code)
    else return this.countriesList.find(country => country.code === code)
  }

  writeValue(value: any): void {
    if (!value) {
      this.globalStore.currentUser$.pipe(take(1)).subscribe((user: User) => {
        if (user.countryCode) this.selectedCountry = this.findCurrentCountry(user.countryCode,true)
        else this.selectedCountry = this.findCurrentCountry(user.currentCountryCode, false)

        setTimeout(() => {
          this.onChange(this.selectedCountry.dial_code);
          this.change.emit(this.selectedCountry.dial_code);
        });
      });
    } else this.selectedCountry = this.findCurrentCountry(value, true)
  }

  changeOption(country: Country) {
    this.selectedCountry = country;
    this.onChange(country.dial_code);
    this.change.emit(country.dial_code);
  }
}
