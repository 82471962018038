  import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  AfterContentInit,
  Input,
  Renderer2, OnDestroy
} from '@angular/core';

@Directive({
  selector: '[appOutsideClick]',
  standalone: true,
})
export class OutsideClickDirective implements AfterContentInit, OnDestroy {
  private _enabled = true;
  private mouseDownListener: () => void;
  private mouseUpListener: () => void;
  private mouseDownOutside = false;

  @Output() appOutsideClick = new EventEmitter<void>();
  @Input() excludeNodes: HTMLElement[] = [];

  get enabled(): boolean {
    return this._enabled;
  }

  @Input()
  set enabled(value: boolean) {
    this._enabled = value;
    if (value) this.subscribe();
    else this.unsubscribe();
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngAfterContentInit() {
    if (this.enabled) this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    setTimeout(() => {
      this.mouseDownListener = this.renderer.listen('document', 'mousedown', (event: Event) => {
        if (!this.excludeNodes.includes(event.target as HTMLElement) && !this.isInCdkOverlay(event.target) && document.contains(event.target as HTMLElement)) this.mouseDownOutside = !this.elementRef.nativeElement.contains(event.target as HTMLElement);
      });
      this.mouseUpListener = this.renderer.listen('document', 'mouseup', (event: Event) => {
        if (!this.excludeNodes.includes(event.target as HTMLElement) && !this.isInCdkOverlay(event.target) && document.contains(event.target as HTMLElement)) {
          if (this.mouseDownOutside && !this.elementRef.nativeElement.contains(event.target as HTMLElement)) {
            this.appOutsideClick.emit();
          }
          else this.mouseDownOutside = false;
        }
      });
    })
  }

  isInCdkOverlay(target: any) {
    let cdkOverlayContainer = document.getElementsByClassName('cdk-overlay-container');
    return cdkOverlayContainer.length > 0 && cdkOverlayContainer.item(0).contains(target);
  }

  unsubscribe() {
    if (this.mouseDownListener) this.mouseDownListener();
    if (this.mouseUpListener) this.mouseUpListener();
  }
}
