import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./components/auth/login/login.component";
import {ForgotPasswordComponent} from "./components/auth/forgot-password/forgot-password.component";
import {CreateNewPasswordComponent} from "./components/auth/create-new-password/create-new-password.component";
import {FillAdditionalInfoComponent} from "./components/auth/fill-additional-info/fill-additional-info.component";
import {RedirectComponent} from "./components/admin/components/redirect/redirect.component";
import {AuthGuard} from "./_guards/AuthGuard";

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: "forgot-password", component: ForgotPasswordComponent},
  {path: "create-new-password", component: CreateNewPasswordComponent},
  {path: "fill-info", component: FillAdditionalInfoComponent, canActivate: [AuthGuard]},
  {path: 'not-found', component: RedirectComponent, canActivate: [AuthGuard], data: {type: 'not-found'}},
  {
    path: '',
    loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule)
  },
  {path: '**', redirectTo: "not-found"},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
