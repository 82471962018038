import {Component, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {UsersService} from "../../../_services/users.service";
import {NgxMaskDirective} from "ngx-mask";
import {User, UserRole} from "../../../_models/user";
import {phoneMask} from 'src/app/config/constants';
import {minLengthValidator, requiredValidator} from "../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";
import {GlobalStore} from "../../../global.store";
import {take} from "rxjs";

@Component({
  selector: 'app-fill-additional-info',
  templateUrl: './fill-additional-info.component.html',
  styleUrls: ['../auth.scss']
})
export class FillAdditionalInfoComponent {
  @ViewChild(NgxMaskDirective, {static: true}) mask: NgxMaskDirective;

  showLoader = false;
  currentUser: User;
  addInfoForm: FormGroup;

  constructor(readonly globalStore: GlobalStore,
              private userService: UsersService,
              private router: Router) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => {
      this.currentUser = user;
      this.addInfoForm = new FormGroup({
        countryCode: new FormControl(this.currentUser.countryCode),
        phone: new FormControl(this.currentUser.phone, [minLengthValidator('PHONE'), requiredValidator()]),
        name: new FormControl(this.currentUser.name, [minLengthValidator('NAME'), requiredValidator()]),
      })
      if (this.currentUser.name && this.currentUser.phone) this.router.navigateByUrl(this.userService.getUserHomePageUrl(this.currentUser));
    })
  }

  onSubmit() {
    if (this.addInfoForm.invalid) return;

    this.showLoader = true;
    const updateData = {...this.currentUser, ...this.addInfoForm.value}
    this.userService.updateUserInfo(updateData).subscribe((data) => {
      this.globalStore.updateCurrentUser(data);

      if (data.role === UserRole.ROLE_SUPERADMIN) this.router.navigate(['/dashboard'])
      else this.router.navigate([`/company/${data.company.id}`])
    }, (error: HttpErrorResponse) => {
      this.showLoader = false;
      if (error.status === 400 && error.error.error === 'Phone already in use') this.addInfoForm.controls['phone'].setErrors({error: 'This phone already in use'});
    });
  }

  protected readonly phoneMask = phoneMask;
  protected readonly UserRole = UserRole;
}



