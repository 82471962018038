import { Injectable } from "@angular/core";
import { Company } from "./_models/company";
import { Project } from "./_models/project";
import { ComponentStore } from "@ngrx/component-store";
import { CompaniesService } from "./_services/companies.service";
import { ResolveEnd, Router } from "@angular/router";
import { ProjectsService } from "./_services/projects.service";
import { User } from "./_models/user";
import { catchError, filter, switchMap, tap } from "rxjs/operators";
import {EMPTY} from "rxjs";
import {Area} from "./_models/area";

export interface GlobalState {
  company: Company | null;
  projectId: number | null;
  isMobile: boolean;
  isNotDesktop: boolean;
  isIOS: boolean;
  currentUser: User | null;
}

@Injectable({ providedIn: 'root' })
export class GlobalStore extends ComponentStore<GlobalState> {
  constructor(
    private companyService: CompaniesService,
    private projectService: ProjectsService,
    private router: Router
  ) {
    super({
      company: null,
      projectId: null,
      isMobile: window.innerWidth <= 639,
      isNotDesktop: window.innerWidth <= 1279,
      isIOS:
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
      currentUser: JSON.parse(localStorage.getItem('user')!) as User,
    });

    this.subscribeToUrlUpdates();
  }

  // Subscribe to router events and trigger handleUrlChange effect
  private subscribeToUrlUpdates() {
    this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event) => {
        const url = (event as ResolveEnd).url;
        const areaIdMatch = url.match(/areas\/([A-Za-z0-9]+-[A-Za-z0-9]+)/);
        if(areaIdMatch) return

        const companyIdMatch = url.match(/company\/(\d+)/);
        const projectIdMatch = url.match(/projects\/(\d+)/);

        const companyId = companyIdMatch ? Number(companyIdMatch[1]) : null;
        const projectId = projectIdMatch ? Number(projectIdMatch[1]) : null;

        this.handleUrlChange({ companyId, projectId });
      });
  }

  // Effect to handle URL changes and load company/project as needed
  private readonly handleUrlChange = this.effect<{
    companyId: number | null;
    projectId: number | null;
  }>((trigger$) =>
    trigger$.pipe(
      tap(({ companyId, projectId }) => {
        if (!companyId) {
          // No companyId in URL, reset state
          this.resetState();
        } else if (this.get().company?.id !== companyId) {
          // Company ID has changed, load new company and project
          this.loadCompanyAndProject({ companyId, projectId });
        } else if (this.get().projectId !== projectId) {
          // Project ID has changed within the same company
          this.setProjectId(projectId);
        }
      })
    )
  );

  // Effect to load company and project
  readonly loadCompanyAndProject = this.effect<{
    companyId: number;
    projectId: number | null;
  }>((trigger$) =>
    trigger$.pipe(
      switchMap(({ companyId, projectId }) => {
        this.patchState({
          company: {
            ...new Company(),
            id: Number(companyId)
          }, projectId: projectId
        });
        return this.companyService.getCompanyByIdShort(companyId).pipe(
          tap({
            next: (company) => {
              if (projectId && !company.projects.some((p) => p.id === projectId)) {
                // Project not found in company
                this.patchState({ company, projectId: null });
                this.router.navigateByUrl(`/company/${companyId}/not-found`);
              } else {
                // Update state with company and projectId
                this.patchState({ company, projectId });
              }
            },
            error: () => {
              // Handle error, reset state and navigate to not-found
              this.resetState();
            },
          }),
          catchError(() => EMPTY)
        )}
      )
    )
  );

  readonly updateCompanyAndProjectOnAreaPage = this.effect<{
    area: Area
  }>((trigger$) =>
    trigger$.pipe(
      tap(({area}) => {
       if (this.get().company?.id !== area.company.id) {
          // Company ID has changed, load new company and project
          this.loadCompanyAndProject({ companyId: area.company.id, projectId: area.project.id });
        } else if (this.get().projectId !== area.project.id) {
          // Project ID has changed within the same company
          this.setProjectId(area.project.id);
        }
      })
    )
  );

  // Updater to set projectId
  readonly setProjectId = this.updater((state, projectId: number | null) => ({
    ...state,
    projectId,
  }));

  // Reset state when no companyId is present
  private resetState() {
    this.patchState({
      company: null,
      projectId: null,
    });
  }

  // Handle window resize events
  onResize() {
    const isMobile = window.innerWidth <= 639;
    const isNotDesktop = window.innerWidth <= 1279;

    if (this.get().isMobile !== isMobile || this.get().isNotDesktop !== isNotDesktop) {
      this.patchState({ isMobile, isNotDesktop });
    }
  }

  // Selectors
  readonly currentUser$ = this.select((state) => state.currentUser);
  readonly isMobile$ = this.select((state) => state.isMobile);
  readonly isNotDesktop$ = this.select((state) => state.isNotDesktop);
  readonly isIOS$ = this.select((state) => state.isIOS);
  readonly company$ = this.select((state) => state.company);
  readonly companyId$ = this.select((state) => state.company?.id ?? null);
  readonly companyName$ = this.select((state) => state.company?.name ?? null);
  readonly project$ = this.select((state) =>
    state.company?.projects.find((e) => e.id === state.projectId) ?? null
  );
  readonly projectId$ = this.select((state) => state.projectId);
  readonly projectName$ = this.select((state) =>
    state.company?.projects.find((e) => e.id === state.projectId)?.name ?? null
  );

  // Updater to update current user
  readonly updateCurrentUser = this.updater((state, user: User | null) => {
    if (user) {
      const updatedUser: User = { ...user };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      return { ...state, currentUser: updatedUser };
    } else {
      localStorage.removeItem('user');
      return { ...state, currentUser: null };
    }
  });

  // Updater to add a project to the company
  readonly addProject = this.updater((state, project: Project) => {
    if (!state.company) return state;
    const updatedCompany = {
      ...state.company,
      projects: [project, ...state.company.projects],
    };
    return { ...state, company: updatedCompany };
  });

  // Updater to update a project
  readonly updateProject = this.updater((state, project: Project) => {
    if (!state.company) return state;

    const updatedProjects = state.company.projects.map((proj) =>
      proj.id !== project.id ? proj : { ...proj, ...project }
    );

    const updatedCompany = { ...state.company, projects: updatedProjects };
    return { ...state, company: updatedCompany };
  });

  // Effect to load project progress
  readonly loadProjectProgress = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        const { projectId, company } = this.get();
        if (!projectId || !company) return EMPTY;

        return this.projectService.getProjectProgressById(projectId).pipe(
          tap((projectProgress) => {
            const updatedProjects = company.projects.map((proj) =>
              proj.id !== projectId ? proj : { ...proj, status: projectProgress }
            );

            const updatedCompany = { ...company, projects: updatedProjects };
            this.patchState({ company: updatedCompany });
          })
        );
      })
    )
  );

  // Updater to delete projects from the company
  readonly deleteProjectsFromCompany = this.updater((state, projectIds: number[]) => {
    if (!state.company) return state;

    const updatedProjects = state.company.projects.filter(
      (p) => !projectIds.includes(p.id)
    );

    const updatedCompany = { ...state.company, projects: updatedProjects };
    return { ...state, company: updatedCompany };
  });

  //Updater to update company
  readonly updateCompany = this.updater((state, company: Company | null) => ({
    ...state,
    company,
  }));
}
