import {Injectable, OnDestroy} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, Subject, Subscription, takeUntil, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from "../_services/auth.service";
import {NotificationService} from "../_services/notification.service";
import {GlobalStore} from "../global.store";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  private companyId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private authService: AuthService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 208) this.notifService.errorNotification("This item has been modified, please open the form again or refresh the page");
        else if (err.status === 400) {
          if (err.error === 'User already exists') this.notifService.errorNotification("Such user already exists")
        }
        else if (err.status === 401) this.authService.logout(true);
        else if (err.status === 403) window.location.href = '/not-found';
        else if (err.status === 404) {
          if (request.params.has("SkipNotFoundNotification")) return throwError(err);
          else if (request.method.toLowerCase() === 'get') {
            if(this.companyId) window.location.href = `company/${this.companyId}/not-found`;
            else window.location.href = '/not-found';
          }
          else this.notifService.errorNotification(`Error. ${err.error.error}`)
        }
        else if (err.status === 500) {
          if (request.method.toLowerCase() === 'post' || request.method.toLowerCase() === 'put') this.notifService.errorNotification("Error. Failed to save data")
          else this.notifService.errorNotification("Error. Internal server error")
        }
        return throwError(err);
      }))
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
